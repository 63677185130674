<div class="background-image-container">
  <div class="container">
    <div class="jumbotron jumbotron-fluid">
      <div class="d-flex flex-wrap">
        <div>
          <h1 class="display-3">DSpace</h1>
          <p class="lead">Welcome to the institutional repository of Amar Telidji University.
            <br>
            (Theses, Publications, Communications, Journals).</p>
        </div>
      </div>
    </div>
  </div>
  <picture class="background-image">
    <source type="image/jpg" srcset="assets/dspace/images/banner.jpg 2000w, assets/dspace/images/banner.jpg 1200w, assets/dspace/images/banner.jpg 768w">
    <img alt="" [src]="'assets/dspace/images/banner.jpg'"/><!-- without the []="''" Firefox downloads both the fallback and the resolved image -->
  </picture>
</div>
